import { React, useState } from 'react';
import { useMsal } from "../msal-context";
import { getEnvironment } from "../Configs/auth-config";
import { Switch, Route, HashRouter, useHistory, BrowserRouter, Redirect, Link } from 'react-router-dom';
import '../styles/pages/Main.css';
import '../styles/pages/Home.css';
import '../styles/components/Hero.css';
import Spinner from './Spinner.js'
import IconWarning from "../images/IconWarning.png"
import upmForest from "../images/upm_timber-wide.jpg"
import { useTranslation } from "react-i18next";

const Home = (props) => {

    const { isAuthenticated, loading, token } = useMsal();
    const [userData, setuserData] = useState(null);
    const [t, i18n] = useTranslation('common');

    if (!sessionStorage.getItem('authenticated')) {
        return <Redirect to={'/'} />
    }

    return (
        <div>
            <div className="an-logo-header an-logo-header--beyond-fossils">
                <div className="an-logo-header__container">
                    <div className="an-logo-header__row">
                        <div className="an-logo-header__title an-logo-header__title--no-company">
                        <span className="an-logo-header__title-site-name"> 
                                <span className="an-logo-header__title-companyname"> UPM Timber</span> User Admin Tool {getEnvironment()}
                        </span> 
                        </div>
                        <div className="an-logo-header__center"></div>
                        <div className="an-logo-header__logo">
                            <div className="an-logo-header__logo-wrapper">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-main">
                {(loading || !props.userData) &&
                    <div className="container-sub home_page_section content-centered">
                        <Spinner text={t('loaders.userAdminDataLoad')} />
                    </div>
                }
                {(isAuthenticated && props.userData && !props.userData.isCompanyAdmin && !props.userData.isUpmAdmin) &&
                    <div className="home_page_section content-centered" style={{ paddingTop: "100px" }}>
                        <span className="an-logo-header__title-site-name home-page-section-text">{t('home.missingRightsHeader')}</span>
                        <img className="home-section-image " src={IconWarning}></img>
                        <span className="an-logo-header__title-site-name home-page-section-text-small">{t('home.missingRightsText')}</span>
                    </div>
                }
                {isAuthenticated && props.userData && (props.userData.isCompanyAdmin || props.userData.isUpmAdmin) &&
                    <div className="hero-wrap">
                        <div className="hero hero--on-dark hero--scale">
                            <img alt="Hoida mets&amp;#228;&amp;#228;si" src={upmForest} className="hero__content-image" />
                            <div className="hero__content-wrapper">
                                <div className="hero__content-text">
                                    <h1 className="hero__header">{t('home.WelcomeTo')} <br/>{t('home.welcomeMessage')} {getEnvironment()}</h1>
                                    {/* <p className="ingress">{t('home.welcomeMessageDesc')}</p> */}
                                    <br />
                                    <div className="hero__link-container">
                                        {/*<a href="/contactus" className="btn btn--arrow-right btn--on-dark-hero">Contact us</a>*/}
                                        <Link className="btn btn--arrow-right btn--on-dark-hero" to="/userslist">{t('header.manageUsers')}</Link>
                                        <Link className="btn btn--arrow-right btn--on-dark-hero" to="/help">{t('header.help')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default Home;