import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from './Breadcrumb';
import { getUsers } from '../services/usersService';
import { useMsal } from "../msal-context";
import { apiRequest, getEnvironment } from "../../src/Configs/auth-config";
import Userstable from './Userstable.js'
import Errorcomponent from './Error.js'
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/pages/Userslist.css';
import Spinner from './Spinner.js'
import Usercreationpopup from './Usercreationpopup.js'
import Userdeletionpopup from './Userdeletionpopup.js'
import Userupdatepopup from './Userupdatepopup.js'
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UsersList = (props) => {

    const { token, getToken } = useMsal();
    const [usersList, setUsersList] = useState();
    const [userEmails, setUsersEmails] = useState([]);
    const [userDeleteData, setUserDeleteData] = useState();
    const [userUpdateData, setUserUpdateData] = useState();
    const [showUserCreationPopup, setShowUserCreationpopup] = useState(false);
    const [showUserDeletePopup, setShowUserDeletePopup] = useState(false);
    const [showUserUpdatePopup, setShowUserUpdatePopup] = useState(false);
    const [userListDataLoading, setUserListDataLoading] = useState(false);
    const [applicationList, setApplicationList] = useState();
    const [sortBy, setSortBy] = useState([]);
    const [error, setError] = useState();
    const [t, i18n] = useTranslation('common');

    const forceLogin = !sessionStorage.getItem('authenticated');

    async function getAllUsers(queryParams) {
        try {
            setUserListDataLoading(true);

            //ensure token is still valid
            let refreshedToken = await getToken(apiRequest, "loginPopup");

            if (!queryParams) {
                queryParams = [];
            }

            await getUsers(refreshedToken, queryParams, props.userData.isCompanyAdmin, props.selectedCompany.companyId, props.userData.email).then(
                (response) => {
                    if (response.status === 200) {
                        let responseData = response.data ? response.data : [];
                        setUsersList(responseData);
                        setUserListDataLoading(false);

                        if (responseData && responseData.length) {
                            let existingUserEmails = responseData.map(user => { return user.email; });
                            setUsersEmails(existingUserEmails);
                        }
                    }
                    else {
                        handleError({ errorCode: response.status, errorMessage: response.errorMessage, stack: "getUsers" })
                    }
                });
        } catch (err) {
            handleError({ errorCode: 'UI', errorMessage: err.message, stack: 'getAllUsers' })
        }
    }

    //hook for storing previous prop value
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const handleCreateUser = () => {
        setShowUserCreationpopup(true);
    }

    const updateUsersList = (newUserData) => {

        if (newUserData) {
            setUsersList(usersList => [newUserData, ...usersList]);
            setUsersEmails(userEmails => [newUserData.email, ...userEmails]);
        }
    }

    const updateUsersListAfterDelete = (userDeleteData) => {

        let usersListAfterDeletion = usersList.filter(user => user.bpSupplierId !== userDeleteData.bpSupplierId);
        setUsersList(usersList => usersListAfterDeletion);
    }

    const handleModifyUser = (userDataRow) => {
        setShowUserUpdatePopup(true);
        setUserUpdateData(() => userDataRow);
    }

    const updateUsersListAfterUpdate = (userUpdateData) => {
        try {
            if (userUpdateData) {
                let updatedUsersList = [...usersList];
                let updatedItem = updatedUsersList.filter(user => user.email === userUpdateData.email);
                if (updatedItem && updatedItem.length) {
                    updatedItem[0].name = userUpdateData.name;
                    updatedItem[0].firstName = userUpdateData.firstName;
                    updatedItem[0].lastName = userUpdateData.lastName;
                    updatedItem[0].isActive = userUpdateData.isActive;
                    setUsersList(updatedUsersList);
                }
            }
        } catch (err) {
            handleError({ errorCode: 'UI', errorMessage: err.message, stack: 'updateUsersListAfterUpdate' })
        }
    }

    const handleDeleteUser = (userDataRow) => {
        setShowUserDeletePopup(true);
        setUserDeleteData(() => userDataRow)
    }

    const handleCancelUserCreation = () => {
        setShowUserCreationpopup(false);
    }

    const handleCancelUserDelete = () => {
        setShowUserDeletePopup(false);
    }

    const handleCancelUserUpdate = () => {
        setShowUserUpdatePopup(false);
    }

    const handleError = (error) => {
        setError(error);
    }

    const getApplicationsForCompany = (userData, selectedCompany) => {
        try {
            let apps = [];

            if (userData && userData.suppliers && selectedCompany.companyId) {

                if (userData.isCompanyAdmin) {
                    let filteredSuppliers = userData.suppliers.filter(x => x.parentSupplier.bpSupplierId === selectedCompany.companyId);
                    const supplier = filteredSuppliers && filteredSuppliers.length ? filteredSuppliers[0] : null;

                    if (supplier) {
                        apps = supplier.applications;
                    }
                } else {
                    apps = userData.suppliers[0].applications;
                }
            }

            return apps;
        } catch (err) {
            handleError({ errorCode: 'UI', errorMessage: err.message, stack: 'getApplicationsForCompany' })
        }
    }

    let prevApp = usePrevious(props.selectedApplication);
    useEffect(() => {
        try {
            prevApp = prevApp || props.selectedApplication;

            if (token && props.selectedCompany) {
                let queryParams = [{ name: "bpSupplierId", value: props.selectedCompany.companyId }];

                if (props.selectedApplication) {
                    queryParams.push({ name: "applicationId", value: props.selectedApplication.applicationId });
                }
                getAllUsers(queryParams);
            }

            if (props.selectedCompany && props.userData) {
                setApplicationList(getApplicationsForCompany(props.userData, props.selectedCompany));
            }
        } catch (err) {
            handleError({ errorCode: 'UI', errorMessage: err.message, stack: "setSelectedCompanyAndApp" })
        }

    }, [props.selectedCompany, props.selectedApplication])

    useEffect(() => {
        return () => { };
    }, []);


    if (forceLogin) {
        return <Redirect to={'/'} />
    }

    if (error) {
        return (
            <Errorcomponent error={error} />
        )
    }

    return (
        <div>
            <div className="an-logo-header  an-logo-header--beyond-fossils">
                <div className="an-logo-header__container">
                    <div className="an-logo-header__row">
                        <a href="#" className="an-logo-header__title ">
                            <span className="an-logo-header__title-site-name"> 
                                                           <span className="an-logo-header__title-companyname"> UPM Timber</span> User Admin Tool {getEnvironment()}
                                                       </span> 
                            {/* <span className="an-logo-header__title-site-name"> {t('usersList.upmUserManagement')}</span> */}
                        </a>
                        <div className="an-logo-header__center">
                            <Breadcrumb breadcrumbData={[{ link: null, displayText: props.selectedCompany ? props.selectedCompany.companyName : '' }, { link: null, displayText: props.selectedApplication ? props.selectedApplication.applicationName : '' }]} />
                        </div>
                        <div className="an-logo-header__logo">
                            <div className="an-logo-header__logo-wrapper">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-main" key="userslist">
                {showUserCreationPopup &&
                    <Usercreationpopup onError={handleError} applications={applicationList} existingUserEmails={userEmails} selectedCompany={props.selectedCompany} userAdminData={props.userData} onUserCreated={updateUsersList} handleCancel={() => handleCancelUserCreation()} />
                }
                {showUserDeletePopup &&
                    <Userdeletionpopup onError={handleError} selectedCompany={props.selectedCompany} userDeletioData={userDeleteData} userAdminData={props.userData} onUserDeleted={updateUsersListAfterDelete} handleCancel={() => handleCancelUserDelete()} />
                }
                {showUserUpdatePopup &&
                    <Userupdatepopup onError={handleError} applications={applicationList} userUpdateData={userUpdateData} selectedCompany={props.selectedCompany} userAdminData={props.userData} onUserUpdated={updateUsersListAfterUpdate} handleCancel={() => handleCancelUserUpdate()} />
                }

                <div className="users-table-wrapper">
                    {(!usersList || userListDataLoading) &&
                        <Spinner text={t('loaders.loadingUsers')} />
                    }
                    {usersList && !userListDataLoading &&
                        <Userstable onModifyUser={handleModifyUser} onDeleteUser={handleDeleteUser} usersList={usersList} sortBy={sortBy} />
                    }
                </div>

                {usersList && usersList.length === 0 &&
                    <div className="users-table-wrapper" style={{ textAlign: "center", marginTop: '20px' }}>
                        <span>{t('usersList.noUsersFound')}</span>
                    </div>
                }
                {usersList && !userListDataLoading &&
                    <div className={(usersList && usersList.length < 13) ? 'create-user-spacer' : ''} style={{ textAlign: "right" }}>
                        <button onClick={() => handleCreateUser()} className="upm-btn btn--secondary cta-block__btn">{t('usersList.addNewUser')}</button>
                    </div>
                }
            </div>
        </div>
    )
};

export default UsersList;