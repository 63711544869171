import '../styles/components/Headerselector.css';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

const Languageselector = (props) => {
    const [showMenu, setshowMenu] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'FI');
    const [languages, setLanguages] = useState(['en', 'fi', 'ge','fr','jpn','chn']);
    const [mounted, setMounted] = useState(false);
    const [t, i18n] = useTranslation('common');

    let wrapperRef = {};

    const handleClickOutside = (e) => {
        if (wrapperRef && !wrapperRef.contains(e.target)) {
            if (showMenu) {
                setshowMenu(false)
            }
        }
    }

    const ontopNavigationSelectorKeyPress = (e) => {
        //Enter
        if (e.which === 13) {
            e.target.click();
            e.stopPropagation();
            if (!e.target.classList.contains("top-navigation-selector")) {
                setshowMenu(false);
            }
        }
        //Escape
        if (e.which === 27) {
            setshowMenu(false);
        }
    }

    const toggleMenu = () => {
        setshowMenu(!showMenu)
    }

    const setWrapperRef = (node) => {
        wrapperRef = node;
    }

    const onChangeLanguage = (e) => {
        try {
            e.preventDefault();

            var languageId = e.target.attributes.languagevalue.value;
            setLanguage(languageId);
            i18n.changeLanguage(languageId.toLowerCase());

            localStorage.setItem('selectedLanguage', languageId.toLowerCase());

             // Dynamically update a specific element's class
             document.documentElement.setAttribute("lang", languageId);
        }
        catch (err) {
            if (props.onError)
                props.onError({ errorCode: 'UI', errorMessage: err.message, stack: 'onChangeLanguage' })
        }
    }

    //component mount event
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        setMounted(true);
    }, [handleClickOutside, mounted]);

    //component unmount event
    useEffect(() => {
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        }
    }, [handleClickOutside])

    return (
        <div className={"top-navigation-selector" + (showMenu ? " showMenu" : "")} onClick={toggleMenu} onKeyDown={ontopNavigationSelectorKeyPress} tabIndex="0" ref={setWrapperRef}>
            <div className="top-navigation-selector__actual language-selector">
                <span className="top-navigation-selector__actual-value">{language ? language.toString().toUpperCase() : 'EN'}</span>
                {languages && languages.length > 1 &&
                    <div className="navigation__chevron-container">
                        <div className="navigation__chevron"></div>
                    </div>
                }
            </div>
            {languages && languages.length > 1 &&
                <ul className="top-navigation-selector__menu">
                    {
                        languages && languages.length && languages.map((languageItem, index) => {
                            return (
                                <li key={languageItem} className={"top-navigation-selector__menu--item" + (languageItem === language || (!language && languageItem === 'en') ? " selected" : "")}>
                                    <a href="#" onClick={onChangeLanguage} key={languageItem} languagevalue={languageItem}>
                                    {{
                                        'FI': t('header.languageFinnish'),
                                        'EN': t('header.languageEnglish'),
                                        'GE': t('header.languageGerman'),
                                        'FR': t('header.languageFrench'),
                                        'JPN': t('header.languageJapanese'),
                                        'CHN': t('header.languageChinese'),
                                    }[languageItem.toString().toUpperCase()]}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            }
        </div>
    )
};

export default Languageselector;