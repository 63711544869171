import React,{ useState, useEffect } from 'react';
import facebook from "../images/icons/facebook.svg";
import twitter from "../images/icons/twitter.svg";
import linkedin from "../images/icons/linkedin.svg";
import youtube from "../images/icons/youtube.svg";
import wechat from "../images/icons/1871574_chat_circle_logo_media_network_icon.svg";
import facebook_hovered from "../images/icons/facebook_hovered.svg";
import twitter_hovered from "../images/icons/twitter_hovered.svg";
import linkedin_hovered from "../images/icons/linkedin_hovered.svg";
import youtube_hovered from "../images/icons/youtube_hovered.svg";
import wechat_popup from "../images/upm-wechat-code-1.webp";
import '../styles/components/Footer.css';
import { useTranslation } from "react-i18next";



const Footer = (props) => {

    const [t, i18n] = useTranslation('common');
    const language = localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'fi';

    const [over, setOver] = useState({
        hover: false,
        name: ""
    });
    const [wechatpop, setwechatpop] = useState(false);

    useEffect(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage") || "fi"; // Default to English
        // ✅ Set [lang=xx] on <html> tag
        document.documentElement.setAttribute("lang", savedLanguage);
    }, []);

    const togglePopup = () => {
        setwechatpop(!wechatpop);
    }

    return (
        <div className="footer-container fixed-footer">
            <div className="upm-footer">
                <div className="upm-footer__row">
                    <div className="upm-footer__bottom-wrap">
                        <div className="upm-footer__bottom-list-container">
                        {language && (language==="en" || language==="fi")? (
                            <ul>
                                <li>{t('footer.copyright')}&nbsp;© {(new Date().getFullYear())} UPM.  </li>
                                <li><a href={t('footer.upmurl')} target="_blank" rel="noopener noreferrer">{t('footer.upm')}</a></li>
                                <li><a href={t('footer.upmtimberurl')} target="_blank" rel="noopener noreferrer">{t('footer.upmtimber')}</a></li>
                                <li><a href={t('footer.legalNoticeurl')} target="_blank" rel="noopener noreferrer">{t('footer.legalNotice')}</a></li>
                                <li><a href={t('footer.privacyPolicyurl')} target="_blank" rel="noopener noreferrer">{t('footer.privacyPolicy')}</a></li>
                                <li><a href={t('footer.feedbackurl')} target="_blank" rel="noopener noreferrer">{t('footer.feedback')}</a></li>
                            </ul>
                             ) : (
                                <ul>
                                <li>{t('footer.copyright')}&nbsp;© {(new Date().getFullYear())} UPM.  </li>
                                <li><a href={t('footer.upmurl')} target="_blank" rel="noopener noreferrer">{t('footer.upm')}</a></li>
                                <li><a href={t('footer.upmtimberurl')} target="_blank" rel="noopener noreferrer">{t('footer.upmtimber')}</a></li>
                                <li><a href={t('footer.legalNoticeurl')} target="_blank" rel="noopener noreferrer">{t('footer.legalNotice')}</a></li>
                                <li><a href={t('footer.privacyPolicyurl')} target="_blank" rel="noopener noreferrer">{t('footer.privacyPolicy')}</a></li>
                            </ul>
                             )
                            }
                            {/* <a data-v-63ff81fc="" href="https://privacy.upm.com/" className="cookie-policy-toggle">{t('footer.cookiePolicySettings')}</a> */}
                        </div>
                    </div>
                     <div className="upm-footer__bottom-wrap">
                     <div className="upm-footer__bottom-list-container upm-footer__right">
                            <ul>
                                <li  
                                    onMouseOver={() => setOver({hover: true,name:"facebook"})}
                                    onMouseOut={() => setOver({hover: false,name:"facebook"})}>
                                    <a href="https://www.facebook.com/UPMGlobal" target="_blank" rel="noopener noreferrer">
                                        <img src={(over.hover && over.name === "facebook") ? facebook_hovered : facebook} 
                                        alt='facebook' 
                                        width="30"
                                        height="30"
                                        />
                                    </a>
                                </li>
                                <li  
                                  onMouseOver={() => setOver({hover: true,name:"twitter"})}
                                  onMouseOut={() => setOver({hover: false,name:"twitter"})}>
                                    <a href="https://twitter.com/UPMSuomi" target="_blank" rel="noopener noreferrer">
                                        <img src={(over.hover && over.name === "twitter")  ? twitter_hovered : twitter} 
                                        alt='twitter' 
                                        width="30"
                                        height="30"
                                        />
                                    </a>
                                </li>
                                <li  
                                  onMouseOver={() => setOver({hover: true,name:"linkedin"})}
                                  onMouseOut={() => setOver({hover: false,name:"linkedin"})}>
                                    <a href="https://www.linkedin.com/company/upm-timber" target="_blank" rel="noopener noreferrer">
                                        <img src={(over.hover && over.name === "linkedin")  ? linkedin_hovered : linkedin} 
                                        alt='linkedin' 
                                        width="30"
                                        height="30"
                                        />
                                    </a>
                                </li>
                                
                                {language && language==="chn" ?(
                                    <li>
                                        <span className='pointer' onClick={togglePopup}>
                                            <img src={wechat} 
                                            alt='wechat' 
                                            width="30"
                                            height="30"
                                            />
                                        </span>
                                    </li>
                                    ) : (
                                    <li  
                                        onMouseOver={() => setOver({hover: true,name:"youtube"})}
                                        onMouseOut={() => setOver({hover: false,name:"youtube"})}>
                                            <a href="https://www.youtube.com/user/upmdotcom" target="_blank" rel="noopener noreferrer">
                                                <img src={(over.hover && over.name === "youtube")  ? youtube_hovered : youtube} 
                                                alt='youtube' 
                                                width="30"
                                                height="30"
                                                />
                                            </a>
                                    </li>
                                    )
                                }
                                <li>
                                <section id="fixed-form-container">
                                    <div onClick={togglePopup} className={"pointer close " +(wechatpop ? "" : "hidden")}>x</div>
                                    <div className={"body " +(wechatpop ? "" : "hidden")}>
                                            <img 
                                            src={wechat_popup}
                                            alt="wechat"
                                            />
                                    </div>
                                </section>
                                </li>
                            </ul>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;